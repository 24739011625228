import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { NextPage } from "next";
import React from "react";
import { LoginLayout } from "../layouts/pages/LoginLayout";

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  subtitle: {},
  body: {
    margin: theme.spacing(4, 0),
  },
}));

const Login: NextPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <LoginLayout title="Login" displayLoginButton={true}>
      <Typography variant="h1" className={classes.heading}>
        Log in to Reclaim
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Welcome back!
      </Typography>
      <Typography variant="body1" className={classes.body}>
        Log in using any email you've connected with Reclaim.
      </Typography>
    </LoginLayout>
  );
};

export default Login;
